import React, { useContext } from "react";
import "./Services.css";
import Card from "../Card/Card";
import HeartEmoji from "../../img/heartemoji.png";
import Glasses from "../../img/glasses.png";
import Humble from "../../img/humble.png";
import { themeContext } from "../../Context";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Services = () => {
  // context
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  // transition
  const transition = {
    duration: 1,
    type: "spring",
  };

  return (
    <>
      <div className="services" id="services">
        {/* left side */}
        <div className="awesome">
          {/* dark mode */}
          <span style={{ color: darkMode ? "white" : "" }}>Our Awesome</span>
          <span>services</span>
          <span>
            Swasik Digital is your one-stop destination for all your digital
            needs,
            <br />
            From stunning website designs to intuitive mobile apps and robust
            custom software,
            <br />
            ensuring your business stays ahead in today's competitive landscape.
          </span>
          <div
            className="blur s-blur1"
            style={{ background: "#ABF1FF94" }}
          ></div>
        </div>
        {/* right */}
        <div className="cards">
          {/* first card */}
          <motion.div
            initial={{ left: "25rem" }}
            whileInView={{ left: "14rem" }}
            transition={transition}
          >
            <Card
              emoji={HeartEmoji}
              heading={"Graphic Designing"}
              detail={
                "Logo Designing, Prototype & Animation, Responsive Web/App Designing"
              }
            />
          </motion.div>
          {/* second card */}
          <motion.div
            initial={{ left: "-11rem", top: "12rem" }}
            whileInView={{ left: "-4rem" }}
            transition={transition}
          >
            <Card
              emoji={Glasses}
              heading={"Software Development"}
              detail={
                "Website, Mobile Apps, Management System, Custom Software and Much More . . ."
              }
            />
          </motion.div>
          {/* 3rd */}
          <motion.div
            initial={{ top: "19rem", left: "25rem" }}
            whileInView={{ left: "12rem" }}
            transition={transition}
          >
            <Card
              emoji={Humble}
              heading={"Social Media Marketing"}
              detail={"Content Creation, SEO, PPC, Results-Driven Approach"}
              color="rgba(252, 166, 31, 0.45)"
            />
          </motion.div>
          <div
            className="blur s-blur2"
            style={{ background: "var(--purple)" }}
          ></div>
        </div>
      </div>
      {/* <div>
        <h2 class="hightlight">inspirational</h2>
        <h3 class="sub-title">Success Stories</h3>
      </div> */}
    </>
  );
};

export default Services;
